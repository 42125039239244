import React from "react"

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <h1>Політика конфіденційності користування сайтом https://deutschmittyson.online</h1>        
      <p>
          Фізична особа-підприємець Севастьянова Анастасія Сергіївна, РНОКПП 3367602522, що здійснює управління сайтом https://deutschmittyson.online, розуміючи важливість забезпечення конфіденційності й недоторканості Персональних та Конфіденційних даних фізичних осіб, користувачів сайтом, укладає з Вами (надалі - «Користувач») Угоду про конфіденційність (далі - «Угода») для визначення порядку одержання, зберігання, обробку, використання й розкриття персональних даних Учасника при користуванні Веб-Сайтом https://deutschmittyson.online (далі - «Веб-Сайт»). 
          Дана Політика конфіденційності та захисту персональних даних (далі — «Політика») встановлює порядок здійснення та обробки персональних даних, види персональних даних, які збираються, мету використання таких персональних даних, взаємодію з третіми особами, заходи безпеки для захисту персональних даних, умови доступу до персональних даних, а також контактну інформацію для користувача щодо отримання доступу, внесення змін, блокування або видалення своїх персональних даних та поводження з будь-якими питаннями, які можуть виникнути у Вас щодо практики захисту персональних даних.
      </p>
      <p>
          <h1>І. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h1>
                  <p>1. Для користування Веб-сайтом та Послугами, які пропонуються на Веб-сайті, Користувач зобов'язаний прийняти умови цієї Угоди. Користувач не має права використовувати Веб-сайт та Послуги, які надаються Веб-сайтом (надалі - «Послуги»), у випадку незгоди з умовами даної Угоди.</p>
          <p>1.2. Веб-сайт https://deutschmittyson.online здійснює діяльність щодо збору особистих даних користувачів та поважає конфіденційність кожного користувача та зобов’язується захищати особисті дані користувачів, будь-яку інформацію, яка може бути використана для аутентифікації та ідентифікації конкретної особи (фізичної особи чи суб’єкту господарської діяльності), яка представляє інтереси такого суб’єкту. Сфера діяльності політики конфіденційності Веб-сайту https://deutschmittyson.online відноситься до будь-яких особистих даних, які ресурс обробляє у відповідності до діючого законодавства про конфіденційність та інформацію.</p>
          <p>1.3. Політика конфіденційності Веб-сайту застосовується до будь-якого використання даного ресурсу, тобто фактично до всіх послуг ресурсу незалежно від того чи користувач отримує доступ до послуг чи ні, яким чином отримується послуга, використовує користувач таку послугу, не залежно від того чи отримана вона через ПК, мобільну версію сайту, мобільний додаток або будь-яким іншим способом.</p>
          <p>1.4. Веб-сайт https://deutschmittyson.online здійснює збір обсягу персональних даних, необхідних для укладення та виконання договору оферти про надання послуг з навчанням мовам і навичкам спілкування ними.</p>
          <p>1.5. Веб-сайт https://deutschmittyson.online не збирає будь-яку інформацію, щодо обробки якої законодавством встановлено певні вимоги, як то інформацію про расове або етнічне походження, про політичні, релігійні або світоглядні переконання, членство в політичних партіях та професійних спілках, засудження до кримінального покарання у скоєнні злочину або засудження до кримінального покарання, а також дані, що стосуються здоров'я, статевого життя, біометричних або генетичних даних (відповідно до статті 7 Закону України «Про захист персональних даних»).</p>
      </p>
      <p>
          <h1>ІІ. ТЕРМІНИ</h1>
          <p>2.1. Для цілей даної політики конфіденційності наведені нижче терміни вживаються у наступному значенні:</p>
          <p>2.1.1. Персональні дані – будь-які дані про особу, які надають змогу прямо або побічно ідентифікувати її. Метою збирання та обробки персональних даних у Системі є зберігання та обслуговування даних користувачів, відповідно до статей 6, 7 Закону України «Про захист персональних даних» та забезпечення реалізації цивільно-правових відносин, надання/отримання та здійснення розрахунків за придбані послуги, відповідно до Податкового кодексу України, Закону України «Про бухгалтерський облік та фінансову звітність в Україні».</p>
          <p>2.1.2. Володілець бази персональних даних – Севастьянова Анастасія Сергіївна, якій за згодою суб’єкта персональних даних на Веб-сайті https://deutschmittyson.online, надано право на обробку цих даних, яка затверджує мету обробки персональних даних у цій базі даних, встановлює склад цих даних та процедури їх обробки, якщо інше не визначено законом.</p>
          <p>2.1.3. Відповідальна особа – визначена Власником бази персональних даних особа, яка організовує роботу, пов’язану із захистом персональних даних при їх обробці, відповідно до закону.</p>
          <p>2.1.4. Веб-сайт – програмно-апаратний комплекс Виконавця, право на використання якого, належить Виконавцю, та який розміщений у мережі Інтернет за адресою: https://deutschmittyson.online.</p>
          <p>2.1.5. Веб-сайт https://deutschmittyson.online -  виступає в якості інтернет – ресурсу (торгового майданчику), і позиціонує себе як « Веб-сайт» для реєстрації, укладення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними шляхом участі в курсах через  в тому числі, але не виключно: текстові, відео, фото, аудіо уроки/повідомлення, онлайн-вебінари, лекції, спрямовані на навчання мовам Замовником і навичкам спілкування ними.</p>
          <p>2.1.6. Адміністратор захисту - це суб'єкт доступу, відповідальний за захист автоматизованої системи від несанкціонованого доступу до інформації</p>
          <p>2.1.7. Обробка персональних даних - це будь-яка дія або сукупність дій зі збирання, реєстрації, накопичення, зберігання, адаптування, зміни, поновлення, використання і поширення (розповсюдження, реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних баз даних.</p>
          <p>2.1.8. Cookie файли — це текстовий файл або файли, що містять невеликий обсяг інформації, які відправляються веб-браузеру і зберігаються на пристрої користувача. До таких пристроїв можна віднести комп'ютер, мобільний телефон або інший пристрій, за допомогою якого користувач відвідує Веб-сайт.</p>
          <p>2.1.9. Виконавець - Фізична особа-підприємець Севастьянова Анастасія Сергіївна, РНОКПП 3367602522, яка надає послуги навчанням мовам і навичкам спілкування ними,  шляхом укладення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними, розміщеному на Веб-сайті: https://deutschmittyson.online.</p>
          Діяльність здійснюється фізичною особою-підприємцем Севастьяновою Анастасією Сергіївною, РНОКПП 3367602522, e-mail: sevastianovaas@gmail.com, тел. +4915237277511.
          <p>2.1.10. Замовник (Користувач) - будь-яка дієздатна особа, що в порядку, передбаченому даним Договором, за власним волевиявленням повністю прийняла (акцептувала) всі його умови без виключення.</p>
          <p>2.2. Для цілей даної політики конфіденційності всі зазначені в розділі ІІ даного Договору терміни використовуються виключно у значенні, обумовленому в розділі ІІ цілей даної політики конфіденційності, незалежно від використання цих термінів в однині або множині, або в інших граматичних формах, відмінках та/або конструкціях.</p>
          <p>2.3. Всі інші терміни, що окремо не визначені в даній політиці конфіденційності, сприймаються і тлумачаться в їх буквальному граматичному значенні виходячи з положень чинного законодавства України, звичаїв ділового обороту, а також мети та предмету даного Договору.</p>
      </p>
      <p>
          <h1>III. МЕТА ЗБОРУ ТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</h1>
          <p>3.1. Мета використання персональних даних:</p>
          <p>3.1.1 Ваші персональні дані використовуються для укладення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними, а також в цілях забезпечення надання Інтернет-сервісів Веб-сайту: https://deutschmittyson.online права для обміну інформацією/новинами, навчальними матеріалами, в тому числі, але не виключно: «Про захист персональних даних», «Про ратифікацію Конвенції про захист осіб у зв’язку з автоматизованою обробкою персональних даних та Додаткового протоколу до Конвенції про захист осіб у зв’язку з автоматизованою обробкою персональних даних стосовно органів нагляду та транскордонних потоків даних», «Про інформацію», «Про рекламу», «Про телекомунікації», «Про захист інформації в інформаційно-телекомунікаційних системах», «Про державну підтримку засобів масової інформації та соціальний захист журналістів», а також відповідно до Правил використання Веб-сайту.</p>
          <p>3.2. Термін зберігання персональних даних:</p>
          <p>3.2.1. Персональні дані зберігаються протягом терміну не більше, ніж це необхідно з метою їх обробки.</p>
          <p>3.3.1. Адміністратором персональних даних Користувача є ФОП Севастьянова Анастасія Сергіївна, РНОКПП 3367602522, e-mail: sevastianovaas@gmail.com, тел. +4915237277511. Адміністратор обробляє персональні дані Користувачів відповідно до Загального Регламенту про захист даних. Надані персональні дані обробляються та можуть зберігатися в Базі персональних даних або в окремій таблиці Бази даних Веб-сайту.</p>
          <p>3.3.2. Персональні дані Користувачів обробляються, серед іншого, в наступній області:
          - для виконання укладених з Користувачем договорів оферти про надання послуг навчанням мовам і навичкам спілкування ними – підставою для обробки даних в цьому випадку буде договір, укладений з Адміністратором шляхом прийняття правил вказаного договору на Веб-сайті за посиланням https://deutschmittyson.online.
          - для ведення облікового запису Користувача – підставою для обробки даних в цьому випадку буде договір, укладений з Адміністратором шляхом внесення оплати за обрані послуги та прийняття правил Веб-сайту https://deutschmittyson.online.
          - для статистичних цілей для внутрішніх потреб Адміністратора – в цьому випадку підставою для обробки даних буде юридично обґрунтований інтерес Адміністратора, що складається в зборі інформації, яка дозволяє розвивати діяльність і приводити послуги у відповідність до потреб Користувачів;
          - для підтвердження виконання Адміністратором його зобов'язань і розгляду претензій, або захисту від претензій, які можуть бути спрямовані проти адміністратора, запобігання або виявлення шахрайства – підставою для обробки даних Користувача в цьому випадку буде юридично обґрунтований інтерес Адміністратора, який є правом захисту, підтвердження виконання зобов'язань і отримання за це належної винагороди від клієнтів Адміністратора.</p>
          <p>3.3.3. Здійснюючи укладення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними на Веб-сайті https://deutschmittyson.online, Користувач вказує певні визначені персональні дані, необхідні для виконання договору про надання послуг. Відмова надати дані, необхідні для реалізації замовлення, тягне за собою неможливість виконання Виконавцем договору про надання послуг.</p>
          <p>3.3.4. Веб-сайт https://deutschmittyson.online залишає за собою право надавати персональні дані Користувача суб'єктам, які можуть співпрацювати при виконанні Договору оферти про надання послуг навчанням мовам і навичкам спілкування ними, укладеного Користувачем, в тому числі, при отриманні платежів по укладеному договору.</p>
          <p>3.3.5. Передані Користувачем персональні дані будуть оброблятися в період, необхідний для реалізації договору оферти про надання послуг навчанням мовам і навичкам спілкування ними, а також претензій по рекламаціях, а також підтвердження виконання зобов'язань Адміністратора і розгляду претензій або захисту від претензій, які можуть бути спрямовані проти Адміністратора, але не довше ніж протягом одного року від дати передачі Адміністратору Користувачем його даних.</p>
          <p>3.3.6. На окреслених умовах Користувач має наступні права у зв'язку з обробкою його персональних даних Адміністратором у зв'язку з роботою Веб-сайту https://deutschmittyson.online право доступу до даних, їх оновлення, право вимагати перенесення даних, їх видалення, подання заперечення щодо обробки даних і право вимагати обмеження їх обробки.</p>
          <p>3.3.7. Користувач має право подавати Веб-сайту https://deutschmittyson.online заперечення з приводу обробки персональних даних Користувача безпосередньо Адміністратором.</p>
          <p>3.4. Використання файлів cookie</p>
          <p>3.4.1.Файли cookie можуть бути вічними (вони називаються постійними файлами cookie) і зберігатися в комп'ютері, поки користувач їх не видалить, або тимчасовими (такі файли cookie називаються сесійними), тобто вони зберігаються тільки до закриття браузера. Крім того, файли cookie діляться на основні (вони встановлюються безпосередньо відвідуваним Веб-сайтом) та сторонні (встановлюються іншими сайтами).</p>
          <p>3.5. Цілі використання  файлів cookie:
          Веб-сайт https://deutschmittyson.online використовує інноваційний підхід до реалізації процесів необхідних для забезпечення повної функціональності Веб-сайту та пристосування змісту Веб-сайту та допоміжних сервісів  до преференцій та уподобань Користувача, а також оптимізація використання Сайту Сервісу. Зокрема, ці файли дозволяють розпізнавати основні параметри Пристрою Користувача та відповідним чином показувати Веб-сайт, пристосований до його індивідуальних потреб;
          - належне обслуговування партнерської програми, включаючи дозвіл на перевірку джерел перенаправлення Користувачів на Веб-сайти Сервісу;
          - можливість користуватися у Сервісі функціями «Взяти участь» і «Придбати», «Купити...».</p>
          <p>3.6. Безпека:</p>
          <p>3.6.1. Веб-сайт https://deutschmittyson.online  не призначений для неповнолітніх користувачів. Адміністратор відповідально ставиться до питань безпеки, особливо щодо осіб, які не досягли повноліття, у зв'язку з чим, зі свого боку, звертаємось до батьків з проханням пояснити своїм дітям питання безпеки в Інтернеті, їх конкретну мету та необхідність використання тих чи інших сервісів сайту.</p>
          <p>3.6.2. Веб-сайт https://deutschmittyson.online вживає необхідні організаційні та технічні заходи для захисту персональної інформації Користувача від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб.</p>
          <p>3.6.3. Дана Політика конфіденційності поширюється тільки на Веб-сайті https://deutschmittyson.online. Якщо за посиланнями, розміщеними на сайті останнього, Користувач зайде на ресурси третіх осіб, Веб-сайт https://deutschmittyson.online за його дії відповідальності не несе.</p>
          <p>3.6.4. Персональні дані Користувача можуть бути використані в юридичних цілях в суді або на етапах, що ведуть до можливого звернення до суду в зв'язку з тим, що Веб-сайт або послуги використовувалися неналежним чином.</p>
          <p>3.6.5 Користувач обізнаний про те, що Адміністратор сайту може бути змушений повідомити персональні дані на вимогу органів державної влади в порядку передбаченому законодавством України.</p>
          <p>3.7. Взаємодія Веб-сайту https://deutschmittyson.online з третіми особами стосовно персональних даних:</p>
          <p>3.7.1. Веб-сайт https://deutschmittyson.online не передає персональних даних третім особам, крім випадків, коли така передача є вимогою законодавства, на прохання суб'єкта персональних даних або в інших випадках, викладених в даній Політиці. Особиста інформація є цінністю та невід'ємним змістом, в тому числі, особистих немайнових прав будь-якої фізичної особи, і тому вживає всіх можливих заходів для захисту особистої інформації користувачів, добровільно та усвідомлено переданої останніми ФОП Севастьянова А.С..</p>

          <p>3.8. Конфіденційність активності суб'єкта персональних даних на Веб-сайті https://deutschmittyson.online.</p>
          <p>3.8.1.Відомості про активність (трафік) Користувачів, які проходять через мережу або електронну пошту Користувача, захищені відповідно до законодавства. Тобто, жодним чином або способом не порушуємо таємницю «Активності» Користувача при використанні останнім сервісів Веб-сайту https://deutschmittyson.online.</p>
          <p>3.8.2. Веб-сайт https://deutschmittyson.online використовує загальноприйняті стандарти технологічного та операційного захисту інформації та персональних даних від втрати, неправильного використання, зміни або знищення. Однак, незважаючи на всі зусилля, Адміністратор не може гарантувати абсолютну захищеність від будь-яких загроз, що виникають за межами регулювання веб-ресурсу.</p>
          <p>3.8.2. Веб-сайт https://deutschmittyson.online забезпечує застосування всіх відповідних зобов'язань щодо дотримання конфіденційності, а також технічних і організаційних заходів безпеки для запобігання несанкціонованого або незаконного розголошення, або обробки такої інформації та даних, їх випадкової втрати, знищення або пошкодження.</p>
          <p>3.8.3. Веб-сайт https://deutschmittyson.online надає доступ до інформації та персональних даних тільки уповноваженим співробітникам, які дали згоду на забезпечення конфіденційності такої інформації і даних.</p>
          <p>3.8.4. Поширення персональних даних без згоди суб'єкта персональних даних або уповноваженої ним особи дозволяється у випадках, визначених законом, і лише (якщо це необхідно) в інтересах національної безпеки, економічного добробуту та прав людини. 
          Умови доступу до персональних даних:
          Порядок доступу до персональних даних третіх осіб визначається умовами згоди користувача, наданої власнику персональних даних на обробку цих даних, або відповідно до вимог закону.
          Користувач має право на отримання будь-яких відомостей про себе в будь-якого суб'єкта відносин, пов'язаних із персональними даними, за умови зазначення прізвища, імені та по батькові, місця проживання (місця перебування) та реквізитів документа, що посвідчує фізичну особу, яка подає запит, крім випадків, встановлених законом.
          Доступ користувача до даних про себе здійснюється безоплатно.
          Відстрочка доступу користувача до своїх персональних даних не допускається.
          Відстрочка доступу до персональних даних третіх осіб допускається у разі, якщо необхідні дані не можуть бути надані протягом тридцяти календарних днів з дня надходження запиту. При цьому загальний термін вирішення питань, порушених у запиті, не може перевищувати сорока п'яти календарних днів.
          Повідомлення про відстрочку доводиться до відома третьої особи, яка подала запит, у письмовій формі з роз'ясненням порядку оскарження такого рішення.
          Рішення про відстрочку або відмову в доступі до персональних даних може бути оскаржено до Уповноваженого Верховної Ради України з прав людини або через суд.</p>

          <p>3.9. Права суб'єкта персональних даних:</p>
          <p>3.9.1. Веб-сайт https://deutschmittyson.online доводить до Вашого відома інформацію про Ваші права як суб'єкта персональних даних, які врегульовані Законом України «Про захист персональних даних», а саме:
          - знати про джерела збору, місцезнаходження своїх персональних даних, мету їх обробки, місцезнаходження або місце проживання (перебування) власника чи розпорядника персональних даних або надати відповідне доручення щодо отримання цієї інформації уповноваженим ним особам, крім випадків, встановлених законом;
          - отримувати інформацію про умови надання доступу до персональних даних, зокрема, інформацію про третіх осіб, яким передаються персональні дані;
          - на доступ до своїх персональних даних;
          - отримувати не пізніше, ніж за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи обробляються персональні дані, а також отримувати зміст таких персональних даних;
          - пред'являти вмотивовану вимогу власнику персональних даних із запереченням проти обробки своїх персональних даних;
          - пред'являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних будь-яким власником і розпорядником персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;
          - на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
          - звертатися зі скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;
          - застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;
          - вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;
          - відкликати згоду на обробку персональних даних;
          - знати механізм автоматичної обробки персональних даних;
          - на захист від автоматизованого рішення, яке має правові наслідки.</p>
          <p>3.10. Для поновлення, отримання доступу, внесення змін, блокування або видалення своїх персональних даних, відкликання згоди на обробку персональних даних, яке Вами надано Веб-сайт https://deutschmittyson.online  відповідно до даної Політики, або в разі наявності будь-яких зауважень, побажань чи претензій щодо Ваших персональних даних, які обробляються, надішліть повідомлення в «Службу турботи», яка знаходиться на сайті https://deutschmittyson.online.</p>
          <p>3.11. Зміна Політики</p>
          <p>3.11.1. Веб-сайт https://deutschmittyson.online залишає за собою право  без попереднього повідомлення Користувача змінювати дану Політику, про це можуть вноситися зміни та доповнення, в тому числі, при зміні вимог законодавства.</p>
          <p>3.11.2. У разі внесення суттєвих змін до цієї Політики, буде розміщено повідомлення на Веб-сайт та вказано термін вступу цих змін в силу. Якщо протягом зазначеного строку користувач не відмовиться від їх прийняття в письмовій формі, це буде означати, що користувач погоджуєтеся з відповідними змінами Політики. Користувач зобов'язується періодично переглядати Політику для для ознайомлення з будь-якими змінами або доповненнями.</p>
      </p>
      <p>
          <h1>IV. ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</h1>
          <p>Я, як суб’єкт персональних даних, надаю свою згоду на обробку своїх персональних даних вказаних мною під час оформлення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними на Веб-сайт: https://deutschmittyson.online, діяльність якого здійснюється ФОП Севастьяновою Анастасією Сергіївною, РНОКПП 3367602522, (далі — Адміністратор) в базі персональних даних Веб-сайту https://deutschmittyson.online з метою забезпечення взятих Адміністратором на себе зобов’язань, для забезпечення відносин у сфері укладення договору оферти про надання послуг навчанням мовам і навичкам спілкування ними, у межах своєї діяльності, а також виконання Адміністратором своїх обов'язків переді мною, як Замовником  послуг, тощо, а саме:</p>
          <p>- прізвище, ім'я, по батькові, адреса проживання, номер мобільного телефону, іншої контактної інформації, паспортні дані, ІПН (РНОКПП), дата народження, місце реєстрації та інша інформація, яка надається мною при погодженні договору оферти, розміщеному на Веб-сайті https://deutschmittyson.online,  тощо;</p>
          <p>- будь-яких інших персональних даних та відомостей, які добровільно надаються Суб’єктом персональних даних Адміністратору, що містяться в документах, що надаються при заповненні анкет, проведенні опитувань, в процесі оформлення заявок, інших документів та виконання укладених договорів, а також дані, які можуть знадобитися Адміністратору у зв'язку із реалізацією мети обробки персональних даних, в тому числі паспортні дані, якщо такі були надані.
          Обробка персональних даних може здійснюватися Севастьяновою Анастасією Сергіївною, РНОКПП 3367602522, e-mail: sevastianovaas@gmail.com, тел. +49152372775113.</p>
          <p>Я погоджуюсь з тим, що ФОП Севастьянова Анастасія Сергіївна, РНОКПП 3367602522, може передавати мої персональні дані третім особам, афілійованим юридичним особам та фізичним особам-підприємцям відповідно до мети їх обробки, банківським установам, фінансовим установам, іншим третім особам, за умови дотримання такими особами вимог чинного законодавства України щодо захисту персональних даних (без додаткового погодження та повідомлення Суб’єкта персональних даних в разі їх передачі).
          Я підтверджую свою згоду з усім вказаним вище, а також, що повідомлений про Адміністратора персональних даних; мету обробки персональних даних; склад і зміст персональних даних; осіб, яким можуть бути передані персональні дані; мені зрозумілі мої права, передбачені Законом України «Про захист персональних даних», а також зобов'язуюся дотримуватися правил користування Веб-сайтом.
          Мені відомо, що Адміністратор залишає за собою право змінювати Правила користування Веб-сайтом (Публічної оферти), а також Політики конфіденційності на свій розсуд.</p>
      </p>

      <p>
          <h2>КОНТАКТИ ВИКОНАВЦЯ:</h2>
          <p>ФОП Севастьянова Анастасія Сергіївна, 
          РНОКПП 3367602522, 
          Контакти: e-mail: sevastianovaas@gmail.com,
          тел. +4915237277511
          </p>
          <p>Остання версія Політики конфіденційності датована: 13 жовтня 2024 року</p>
      </p>
    </div>
  )
};

export default PrivacyPolicy;
