import React from "react"
import "../styles/Contacts.css"
import { EmphasiseEvolventa, Blue } from "./CustomElements"
import { Link } from "react-router-dom";

const Contacts = (props) => {
  return (
    <div>
      <div className="contacts-container">
        <h1><EmphasiseEvolventa><Blue>Контакти</Blue></EmphasiseEvolventa></h1>
        <div className="contacts-main">
          <h2>Зв'яжіться зі мною!</h2>
          <p>Якщо у вас є запитання або ви хочете дізнатися більше про онлайн курс з німецької, напишіть мені і я відповім вам найближчим часом на всі ваші питання, мур-мур.</p>
          <h2>Мої контакти:</h2>
          <Link to="https://t.me/sevastianovaas" target="_blank"><button>Telegram-канал</button></Link>
          <Link to="https://www.instagram.com/deutsch_mit_tyson/" target="_blank"><button>Instagram</button></Link>
          <Link to="mailto:sevastianovaas@gmail.com"><button>Електронна пошта: sevastianovaas@gmail.com</button></Link>
        </div>
      </div>
    </div>
  )
};

export default Contacts;
