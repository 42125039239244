import React from "react"
import { Link } from "react-router-dom";
import CoursePhoto1 from '../img/course-photo-1.png'

const CourseA0CTA = (props) => {
  return (
    <div>
        <div className={`course-1-cta-container ${props.class}`}>
            <div>
                <div>
                    <h2>
                        {props.creativeText}
                    </h2>
                </div>
                <img src={CoursePhoto1} alt="course-1-cta" className="course-1-cta-img"/>
            </div>
            <Link to={"/course-1"}><button>Записатись на курс A1</button></Link>
        </div>
    </div>
  )
};

export default CourseA0CTA;
