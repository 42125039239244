import React from "react"
import "../styles/Faq.css"
import { EmphasiseEvolventa, Blue } from "./CustomElements"
import { Link } from "react-router-dom";

const FAQ = (props) => {
  return (
    <div>
      <div className="faq-container">
        <h1><EmphasiseEvolventa><Blue>FAQ</Blue></EmphasiseEvolventa></h1>
        <div>
            <h2 className="faq-question">Які є способи оплати онлайн курсу німецької мови? </h2>
            <h2 className="faq-answer">Оплатити курс німецької онлайн можна будь-яким зручним для вас онлайн способом: ApplePay, GooglePay, банківська картка, а також європейською банківською карткою.</h2>
        </div>
        <div>
            <h2 className="faq-question">Якщо у мене не проходить оплата?</h2>
            <h2 className="faq-answer">Якщо у вас не виходить оплатити або є якісь труднощі/запитання — звертайтеся до нашої Служби турботи.</h2>
            <Link to={"/contacts"}><button className='blue'>Контакти</button></Link>
        </div>
        <div>
            <h2 className="faq-question">Коли я отримаю доступ до матеріалів з онлайн курсу німецької мови?</h2>
            <h2 className="faq-answer">Усі уроки та домашні завдання ви отримаєте одразу в перший день після старту онлайн курсу німецької мови. Так, усі матеріали ви отримуєте одразу і можете робити все у зручному для вас темпі.</h2>
        </div>
        <div>
            <h2 className="faq-question">Якщо в мене є запитання, як можна отримати відповідь?</h2>
            <h2 className="faq-answer">Ви можете заповнювати Google Form із запитаннями, на які я буду відповідати на онлайн уроках німецької. Також ви можете ставити запитання під час вебінару.</h2>
        </div>
        <div>
            <h2 className="faq-question">Чи можна отримати запис онлайн уроку з німецької мови/вебінару?</h2>
            <h2 className="faq-answer">Так, звісно. Усі відео уроки німецької надаються одразу, а записи онлайн зустрічей будуть у доступі й не будуть видалятися. Усі відео (живих зустрічей та уроків) завантажити на телефон/комп’ютер неможливо, але ви зможете завантажити мегабайти для перегляду матеріалів у офлайн.</h2>
        </div>
        <div>
            <h2 className="faq-question">Коли проходитимуть уроки онлайн з німецької мови?</h2>
            <h2 className="faq-answer">Живі заняття будуть проходити раз на тиждень у будній день ввечері. Записи наших живих вебінарів будуть у доступі.</h2>
        </div>
        <div>
            <h2 className="faq-question">Де проходять живі вебінари/уроки з німецької онлайн?</h2>
            <h2 className="faq-answer">Онлайн уроки німецької будуть проходити у Zoom у будній день ввечері, а публікуватися у нашому Telegram-каналі.</h2>
        </div>
        <div>
            <h2 className="faq-question">Якою мовою викладається курс з німецької мови?</h2>
            <h2 className="faq-answer">Курс з німецької читається українською мовою.</h2>
        </div>
        <div>
            <h2 className="faq-question">Що якщо я не зможу приєднатися до онлайн уроку/вебінару з німецької?</h2>
            <h2 className="faq-answer">Ви зможете переглянути все у записі, які будуть доступні у Telegram-каналі.</h2>
        </div>
        <div>
            <h2 className="faq-question">Якщо я не можу почати курс вчасно, чи можу я доєднатися потім?</h2>
            <h2 className="faq-answer">У вас є 3 тижні після старту, коли ви зможете приєднатися до потоку курсу.</h2>
        </div>
        <div>
            <h2 className="faq-question">Коли потрібно здавати домашні завдання?</h2>
            <h2 className="faq-answer">По мірі їх виконання, а також упродовж тижня. Для тих хто буде здавати домашки упродовж тижня буде бонус — розмовний клуб у Zoom.</h2>
        </div>
        <div>
            <h2 className="faq-question">Що потрібно для того, щоб пройти курс? </h2>
            <h2 className="faq-answer">Оплата, інтернет, телефон/комп'ютер, зошит, бажання навчатися — щодо останнього з мотивацією можемо і допомогти.</h2>
        </div>
        <div>
            <h2 className="faq-question">Чи є вікові обмеження або за рівнем німецької?</h2>
            <h2 className="faq-answer">Великі курси розділені по рівнях і обмежуються саме цими рівнями. Міні інтенсиви доступні для всіх — як для дітей, так і для дорослих.</h2>
        </div>
      </div>
    </div>
  )
};

export default FAQ;
