import React from "react"

const RefundPolicy = (props) => {
  return (
    <div>
      <div className="refund-policy-container">
        <h1>Політика повернення коштів</h1>        
        <p>
          1. Виконавець надає доступ до навчальних матеріалів «Книги» та «Курс» одразу після оплати. Оскільки доступ надається одразу, шляхом перенесення Інтернет-сторінки до навчальних матеріалів, тому  Виконавець не повертає кошти. 
        </p>
        <p>  
          2. Замовник має право на повернення коштів за Живий курс, за умови надання запиту за 2 дні до старту Живого курсу.
        </p>  
        <p>  
          3. Після того, як цей термін мине, Завомник втрачає право повернути кошти за Живий курс. 
        </p>
        <p>  
          4. Запит у вільній формі відправляється в чат підтримки в Telegram шляхом натискання кнопки "Служба турботи"  на головній сторінці сайту.
        </p>
        <p>  
          5. Виконавець приймає рішення щодо задоволення запиту протягом 2 днів, повернення – 14 днів.
        </p>
      </div>
    </div>
  )
};

export default RefundPolicy;
