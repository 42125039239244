import React from "react";
import '../styles/recommendations.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmphasiseEvolventa, Blue, EmphasiseMontserrat } from "./CustomElements";
import { faBrain, faListAlt, faChalkboardTeacher, faMicrophone, faChartLine, faHeart, faClock, faLightbulb, faComments, faRocket } from "@fortawesome/free-solid-svg-icons";

const Recommendations = (props) => {
  const iconsColor = "#ffffff"

  return (
    <div>
      <div className="recommendations">
        <div>
          <h2><EmphasiseEvolventa><Blue>Benefits</Blue></EmphasiseEvolventa></h2>
          <div className="benefits-grid">
            <div>
              <FontAwesomeIcon icon={faBrain} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Структурована програма курсу</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faListAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Сучасні техніки навчання</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faChalkboardTeacher} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Складні теми простими словами</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faClock} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Доступ до матеріалів 24/7</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faLightbulb} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Постійний фідбек від викладача</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faComments} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Подолання мовних бар'єрів</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faRocket} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Вивчення актуальних життєвих тем</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faHeart} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Постійна мотивація і підтримка</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faChartLine} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Покроковий підхід до результату</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faMicrophone} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Щотижнева розмовна практика</h2>
            </div>
          </div>
        </div>
        <div>
          <h2><EmphasiseEvolventa><Blue>Про мене</Blue ></EmphasiseEvolventa></h2>
          <p>Понад 10 років живу в Берліні й 4 з них з котом Тайсоном, поліглот і нестереотипний <Blue><EmphasiseMontserrat>викладач німецької з 9-річним досвідом.</EmphasiseMontserrat></Blue> Розробила власну методику навчання, де головний <Blue><EmphasiseMontserrat>акцент на результаті:</EmphasiseMontserrat></Blue> ви зрозумієте та заговорите німецькою впевнено. Якщо ви шукаєте вивчення німецької з харизмою та «не так, як усюди», то вам до мене!</p>
        </div>
        <div className="social-proof">
          <h2><EmphasiseEvolventa><Blue>Факти, що говорять самі за себе</Blue></EmphasiseEvolventa></h2>
            <p>
              <EmphasiseMontserrat>4 вищі освіти</EmphasiseMontserrat>, з них 2 філологічні
            </p>
            <p>
              <EmphasiseMontserrat>9 років </EmphasiseMontserrat>викладаю німецьку мову
            </p>
            <p>  
              <EmphasiseMontserrat>400+</EmphasiseMontserrat> студентів вже пройшли навчання зі мною
            </p>
            <p>
              <EmphasiseMontserrat>97,5%</EmphasiseMontserrat> моїх студентів, які готувалися до іспитів, успішно склали Goethe, ÖSD, TestDaF
            </p>
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
