import React from "react"
import NastyaMain from "../img/nastya-main-3.png"
import WeInSocialNetwork from "./WeInSocialNetwork";
import ReviewSlider from "./ReviewSlider";
import LiveCourse from "./LiveCourse";
import CareService from "./CareService";
import CourseA0CTA from "./CourseA0CTA";
import CourseA0CTACreative from "./CourseA0CTACreative"
import Recommendations from "./Recommendations";
import { EmphasiseMontserrat, Beige } from "./CustomElements";

const Home = (props) => {
  return (
    <div>
        <div className="main-container">
          <div className="name-container">
            <div className="name-container__small">
              <h1>Анастасія Севастьянова</h1>
              <p className="">Викладач, що робить німецьку <EmphasiseMontserrat><Beige>твоєю</Beige></EmphasiseMontserrat> мовою.</p>
            </div>
            <CourseA0CTA />
          </div>
          <img src={NastyaMain} alt="woman-decoy" />
        </div>
        <WeInSocialNetwork instagram={true} additionalClass={'small-margin'}/>
        <CareService class={'care-service-relative'}/>
        <Recommendations/>
        <CourseA0CTACreative class={'wholescreen columns'} creativeText={"Потрібен результат, а не обіцянки? Запишись на авторський курс A1 і почни говорити німецькою впевнено!"} />
        <ReviewSlider />
        <LiveCourse courseDate="2024-11-22T00:00:00" courseName="Німецька A1 прям з 0" />
    </div>
  )
};

export default Home;
