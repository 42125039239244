import React from "react"

const ContractForm = (props) => {
  return (
    <div className="contract-form-container">
        <h1>Публічний договір(Оферта) про надання послуг з навчання мовам і навичкам спілкування ними </h1>

        <p>Фізична особа-підприємець - Севастьянова Анастасія Сергіївна, РНОКПП 3367602522,  (надалі - «Виконавець»), яка діє на підставі  Виписки з єдиного державного реєстру з однієї сторони, цим договором пропонує дієздатним фізичним особам (надалі - «Замовник») з іншої сторони, разом іменовані як «Сторони», а кожна окремо — «Сторона», - акцептувати цю публічну оферту пропозицію (укласти договір про надання послуг). Даний договір розміщений на сайті https://deutschmittyson.online  Виконавця.</p>


        <h1>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h1>

        <p>1.1. Цей Договір укладається шляхом надання повної й безумовної згоди (акцепту) Замовника на укладення Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.</p> 
        <p>1.2. Даний Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України і є рівносильним Договору, підписаному Сторонами.</p> <p> 1.3. Замовник підтверджує факт ознайомлення та згоди з усіма положеннями цього Договору в повному обсязі шляхом його акцептування.</p>
        <p>1.4. Оплата Замовником послуг Виконавця на умовах та в порядку, що визначені цим Договором та на відповідних сторінках Веб-сайту Виконавця вважається акцептом цього Договору публічної оферти.</p>
        <p>1.5. Укладаючи (акцептуючи) даний Договір Замовник автоматично погоджується з повним та безумовним прийняттям положень цього Договору, цін на Послуги та всіх додатків, які є невід’ємними частинами цього Договору.</p> 
        <p>1.6. Якщо Замовник не згодний з умовами Договору, він має право не укладати цей Договір, а також не вправі користуватися Послугами за цим Договором.</p>


        <h1>2. ТЕРМІНИ ТА ВИЗНАЧЕННЯ</h1>

        <p>2.1. Публічний договір(договір публічної оферти) – правочин, що регулює відносини між Виконавцем та Замовником, щодо надання інформаційних послуг на умовах, встановлених Виконавцем.</p>
        <p>2.2. Виконавець - Фізична особа-підприємець - Севастьянова Анастасія Сергіївна, РНОКПП 3367602522, що надає послуги з навчанням мовам і навичкам спілкування ними.</p>
        <p>2.3. Замовник – будь-яка особа, що надала згоду на укладання Договору надання послуги з навчанням мовам і навичкам спілкування ними.</p>
        <p>2.4. Сторони – вказівка в Договорі разом на Виконавця та Замовника.</p>
        <p>2.5. Веб-сайт – програмно-апаратний комплекс Виконавця, право на використання якого, належить Виконавцю, та який розміщений у мережі Інтернет за адресою: https://deutschmittyson.online</p>
        <p>2.6. Ресурси Веб-сайту – власне Веб-сайт, як складний об’єкт авторського права, інші об’єкти права інтелектуальної власності, розміщені на Веб-сайті, в тому числі, але не виключно, Програмне забезпечення (комп’ютерні програми і алгоритми дій), бази даних, їх каталоги, графічні зображення, звукові сигнали, тексти та інша інформація, впорядковані за встановленою системою та логічно пов’язані між собою цілями, для яких створювався Веб-сайт. Дані об'єкти інтелектуальної власності належать Виконавцю у повному обсязі.</p>
        <p>2.7. Послуги – послуги з навчанням мовам і навичкам спілкування ними, що надаються Виконавцем в тому числі, але не виключно, шляхом допуску до навчальних матеріалів, відео-конференцій та консультацій онлайн.</p>
        <p>2.8. Навчальні матеріали – сукупність різних окремих матеріалів, які є об’єктами авторського права Виконавця та надаються Виконавцем з метою надання послуги з навчання мовам і навичкам спілкування ними Замовника, підлягають використанню Замовником виключно для отримання знань та здобуття навичок.</p>
        <p>2.9. Графік занять - інформація про терміни надання послуг, конкретні дати, час, кількість занять, що розміщені на Веб-сайті за адресою у мережі Інтернет: https://deutschmittyson.online</p>
        <p>2.10. Книги – різновид навчальних матеріалів у вигляді файлу pdf, в якому системно викладено інформацію з галузі навчання мови, а саме граматику та вправи для самостійного навчання.</p>
        <p>2.11. Курс – різновид навчальних матеріалів у вигляді в тому числі, але не виключно, відео-записів, аудіо-записи, фото, презентації, методології,  файли з правилами, наукові матеріали, вправи для самостійного навчання.</p>
        <p>2.12. Живий курс - різновид навчальних матеріалів у вигляді в тому числі, але не виключно, відео-конференції, лекції, відео-записів, фото, презентації, методології, аудіо-записи, файли з правилами, наукові матеріали, вправи з консультацією Виконавця.</p>


        <h1>3. ПРЕДМЕТ ДОГОВОРУ</h1>

        <p>3.1. Виконавець надає послуги навчанням мовам і навичкам спілкування ними  Замовнику за плату за допомогою персональних комп’ютерів та мобільних пристроїв, що мають доступ до мережі Інтернет, а також надає доступ до навчальних матеріалів, консультацій Виконавця в залежності від обсягу наданих послуг.</p> 
        <p>3.2. Формат, перелік та об’єм наданих послуг визначається Виконавцем та передбачений на сайті https://deutschmittyson.online.</p>


        <h1>4.УМОВИ НАДАННЯ ПОСЛУГ</h1>

        <p>4.1. Виконавець надає доступ Замовнику до послуг та навчальних матеріалів, розміщених у Youtube, закритих WhatsApp-каналах, Telegram та закритих профілях соціальної мережі Instagram (далі – «закритих каналів») або у інших додатках та платформах.</p>
        <p>4.2. Доступ до послуг надається Замовнику за умови здійснення оплати повної вартості послуг.</p>
        <p>4.3. Придбання підтверджується заповненням Замовником відповідної заявки та внесенням оплати одним із способів зазначених в п. 3.2. цього Договору та вказаних на сайті https://deutschmittyson.online при натисканні на кнопку «Купити».</p>
        <p>4.4. Для надання доступу Замовнику до закритих каналів необхідно повідомлення Замовником своїх контактних даних, а саме: Ім'я та прізвище, мобільний номер телефону, e-mail.
        Виконавець не несе відповідальності за точність, законність і правдивість персональних даних Замовника, наданих Замовником Виконавцю з метою реєстрації його, в якості учасника онлайн-курсу та надання йому доступу до закритих каналів.
        Надання послуги шляхом підключення до закритих каналів/чатів/груп здійснюється після отримання Виконавцем підтвердження оплати послуг Замовником.</p>
        <p>4.5. Доступ Замовнику до послуги надається з моменту підтвердження отримання Виконавцем необхідної суми оплати.
        Якщо по завершенню цього терміну Замовник, з будь-якої причини, не отримав доступу до послуг, Замовник має звернутися до служби підтримки Виконавця, посилання на яку розміщено на сайті https://deutschmittyson.online, з підтвердженням оплати і повідомити про це за посиланням https://t.me/sevastianovaas.</p>
        <p>4.6. Виконавець, в односторонньому порядку може тимчасово припинити доступ Замовника до онлайн-курсу в разі порушення Замовником умов даного Договору та Правил конфіденційності, до моменту припинення порушень або видалити учасника онлайн-курсу. При цьому кошти за оплачені послуги Замовнику не повертаються.</p>


        <h1>5. ПРАВА ТА ОБОВ’ЯЗКИ ВИКОНАВЦЯ</h1>

        <p>5.1. Виконавець зобов’язаний:</p>
        <p>5.1.1. Виконувати умови даного Договору;</p>
        <p>5.1.2. Надати Замовнику Послуги належної якості;</p>
        <p>5.1.3. Виконавець зобов'язується розглянути вимогу Замовника про повернення коштів за Живий курс, надіслану шляхом натискання кнопки «Служба підтримки» на Веб-сайті, протягом трьох робочих днів.</p>
        <p>5.1.4. У разі, коли заявка Замовника на повернення коштів за Живий курс, надіслана Замовником з дотриманням п.6.2.2. та строків, передбачених в даному Договорі, Виконавець зобов'язується повернути кошти Замовнику після розгляду заявки  протягом 7 робочих днів.</p> 

        <div>
        <p>5.2. Виконавець має право:</p>
        <p>5.2.1. Запитувати у Замовника всю необхідну інформацію, документи та інше для належного виконання зобов'язань за цим Договором.</p>
        <p>5.2.2. Вносити зміни в Графік занять з обов'язковим повідомленням про це Замовника не пізніше, ніж за 1 (один) робочий день до очікуваної дати наступного заняття.</p>
        <p>5.2.3. У разі розірвання даного Договору або припинення надання послуг Замовнику, Виконавець вправі в будь-який час, без попередження і узгодження із Замовником видалити всі надані ним дані без можливості їх відновлення. У даному випадку Виконавець звільняється від будь-яких зобов'язань, пов'язаних з відповідними даними Замовника.</p> 
        <p>5.2.3. Виконавець має право змінювати умови цього Договору в односторонньому порядку, шляхом публікації на сайті  https://deutschmittyson.online нової редакції із зазначенням дати внесення змін.</p>
        <p>5.2.4. Виконавець має право не повертати кошти, отримані за Книги та Курс, оскільки Замовник отримує доступ до навчальних матеріалів після оплати безповоротно.</p>
        <p>5.2.5 Виконавець має право використовувати відгуки Замовника без обмежень і компенсації для Замовника.</p>
        <p>5.2.6 Виконавець має право залучати третіх осіб за їх згодою для надання послуг.</p>
        <p>5.2.7 Виконавець має право не повертати кошти Замовнику, у випадку порушення Замовником умов публічного договору, навіть якшо вимога про повернення коштів була надіслана у строки вказані у публічному договорі.</p> 
        </div>



        <h1>6. ПРАВА ТА ОБОВ'ЯЗКИ ЗАМОВНИКА</h1>

        <div>
        <p>6.1 Замовник зобов’язаний:</p>
        <p>6.1.1 Своєчасно оплатити послуги з надання навчальних матеріалів та консультацій Виконавця.</p>
        <p>6.1.2. Не поширювати, не присвоювати, не надавати у користування третім особам навчальні матеріали, отримані в ході надання Послуг, а також не записувати їх на аудіо-та / або відеоносії, не відтворювати, не повторювати, не копіювати, не продавати їх. У разі, якщо Замовник допустить поширення зазначеної інформації, він несе відповідальність перед Виконавцем за завдані таким поширенням інформації збитки у відповідності до чинного законодавства.</p>
        <p>6.1.3. Не вчиняти дії щодо несанкціонованого збору, зберігання, використання або розголошення персональних даних Замовників Виконавця, отриманих в процесі отримання послуг.</p>
        <div><p>6.1.4 Відшкодовувати витрати та збитки, спричинені:</p>
        <p>А) використанням або спробою використання Послуг із порушенням цих Умов</p>
        <p>Б) порушенням закону або прав третіх осіб</p>
        <p>В) присвоєнням інтелектуальної власності чи порушенням інших майнових прав</p></div>
        </div>

       <div> <p>6.2 Замовник має право:</p>
        <p>6.2.1 Замовник користується всіма правами визначеними чинним законодавством України, зокрема Конституцією України, ЗУ «Про захист прав споживачів», Цивільним кодексом України.</p>
        <p>6.2.2 В разі настання обставин, через які Замовник не може приймати участь, Замовник вправі звернутися до Виконавця з вимогою про повернення коштів щонайменше за два дні до старту Живого курсу, шляхом натискання кнопки «Повернути кошти», розміщеної на Веб-сайті за посиланням https://deutschmittyson.online.</p>
        <p>6.2.3 Замовник має право залишати відгуки на Веб-сайті або інших платформах.</p></div> 


        <h1>7. ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ</h1>

        <p>7.1. Ціна Послуги визначається Виконавцем та зазначається на відповідній сторінці Веб-сайту</p>
        <p>7.2. Оплата Послуг здійснюється шляхом перерахування грошових коштів на поточний рахунок Виконавця або за допомогою інших платіжних засобів, зазначених в повідомленні, після реєстрації і вибору способу оплати Замовником, отриманих на адресу електронної пошти. Датою оплати вважається дата надходження коштів на рахунок Виконавця.</p>


        <h1>8. ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ</h1>

        <p>8.1. За невиконання або неналежне виконання своїх зобов’язань за цим Договором, Сторони несуть відповідальність відповідно до чинного законодавства України.</p>
        <p>8.2 Сторони дійшли згоди, що відповідальність за результат несе Замовник. Виконавець надає навчальні матеріали та консультації, в залежності від обсягу наданих послуг.</p>
        <p>8.3 Сторони дійшли згоди, що Виконавець не несе відповідальність в тому числі, але не виключно: за штрафні збитки, втрачену вигоду та інше пов’язане з доступом до послуг, їх використанням або неможливістю такого доступу чи використання.</p>
        <p>8.4. Усі спори, що виникають з цього Договору або пов'язані із ним, вирішуються шляхом переговорів між Сторонами.</p>
        <p>8.5. В разі неможливості вирішення спору, шляхом переговорів, спір вирішується у відповідності до Законодавства України.</p>


        <h1>9. ФОРС-МАЖОРНІ ОБСТАВИНИ</h1>

        <p>9.1. Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов'язань, що передбачені даним Договором, якщо воно виникло внаслідок форс-мажорних обставин.</p> 
        <p>9.2. Під форс-мажорними обставинами у даному Договорі розуміються будь-які обставини, що виникли поза волею або всупереч волі чи бажанню Сторін і яких не можна передбачити чи уникнути, включаючи: військові дії, громадські заворушення, епідемії, блокаду, землетруси, повені, пожежі, які роблять неможливим подальше повне або часткове виконання Договору, а також інші дії чи події, що існують поза волею Сторін.</p>

        <h1>10. ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ</h1> 

        <p>10.1. Виконавець є правовласником об'єктів інтелектуальної власності, включаючи, але не обмежуючись: тексти, фотографії, відеоматеріали, графічні зображення, музичні та звукові твори, товарні знаки, фірмове найменування.</p> 
        <p>10.2 Виконавець є автором навчальних матеріалів і має виключне право розпорядження майновими та немайновими правами на об’єкти авторського права.</p>
        <p>10.3 Надання навчальних матеріалів Замовнику не надає права розпорядження майновими та немайновими правами на об’єкти авторського права Виконавця.</p>
        <div><p>10.4. Замовнику забороняється, якщо інше не випливає прямо з чинного законодавства України, та Замовник з цим погоджується:</p> 
        <p>А) продавати, передавати в користування, давати напрокат або в оренду, експортувати, імпортувати, поширювати, передавати в забезпечення будь-якій третій особі, видавати субліцензії або іншим способом надавати права третім особам на Веб-сайт, об’єкти права інтелектуальної власності, права на які належать Виконавцю;</p>
        <p>Б) видаляти, ховати або змінювати будь-які повідомлення про права інтелектуальної власності, які містяться на Веб-сайті та/або інших об’єктах права інтелектуальної власності;</p>
        <p>В) використовувати Веб-сайт та/або інші об’єкти права інтелектуальної власності (або будь-яку їх частину) в комерційних продуктах або послугах для надання таких продуктів або послуг третім особам або примушувати таке використання. Це положення не забороняє використовувати Веб-сайт та/або інші об’єкти права інтелектуальної власності, (або будь-яку їх частину) для власних потреб за умови, що таке використання не суперечить умовам, зазначеним у пункті 7.2. цього Договору;</p>
        <p>Г) будь-яким чином використовувати Веб-сайт, та/або інші об’єкти права інтелектуальної власності, права на які належать Виконавцю, з обходом встановлених заходів їх захисту;</p>
        <p>Д) здійснювати інші дії, які порушують майнові права Виконавця на Веб-сайт та/або інші об’єкти права інтелектуальної власності.</p>
        </div>
        <h1>11.ЗАХИСТ ПЕРСОНАЛЬНИХ ДАНИХ ТА КОНФІДЕНЦІЙНОЇ ІНФОРМАЦІЇ</h1>

        <p>11.1. Погоджуючись з умовами оферти і приймаючи її умови, Замовник надає Виконавцю однозначну згоду на обробку та використання будь-яких персональних даних, які стали відомими Виконавцю в результаті надання Послуг на умовах цього Договору відповідно до законодавства України в сфері захисту персональних даних.</p>
        <p>11.2. Обробка персональних даних включає, але не обмежується, збір, реєстрацію, накопичення, зберігання, адаптування, зміна, оновлення, використання і поширення (розповсюдження, реалізацію, передачу), знеособлення, знищення персональних даних, які обробляються Виконавцем, будь-якою особою, пов'язаним з Виконавцем відносинами контролю з метою ведення бази персональних даних Замовників.</p>
        <p>11.3. Замовник погоджується, що Виконавець не повинен отримувати додаткової згоди Замовника для передачі персональних даних Замовника будь-якій особі, пов'язаній з Виконавцем відносинами контролю або в рамках договірних відносин.</p>
        <p>11.4. Вся інформація, яка стала відома Замовнику в зв'язку з отриманням Послуг від Виконавця згідно даної оферти (в тому числі паролі доступу до Програм), є конфіденційною інформацією і комерційною таємницею Виконавця.</p>
        <p>11.5. Замовник зобов'язується не розголошувати і не передавати конфіденційну інформацію та комерційну таємницю Виконавця для ознайомлення і / або використання третім особам без попередньої письмової згоди Виконавця.</p>
        <p>11.6. Замовник погоджується, що в разі порушення Замовником умов конфіденційності визначених в пп. 11.1.-11.5. цього Договору, Виконавець може вимагати від Замовника відшкодування завданої шкоди та користуватись всіма правами наданими чинним законодавством на відновлення порушених прав.</p>

        <h1>12. СТРОК ДІЇ ДОГОВОРУ ТА ПРАВО, ЩО ЗАСТОСОВУЄТЬСЯ</h1>

        <p>12.1. Цей Договір є публічним і безстроковим та діє до його припинення будь-якою зі Сторін у порядку, встановленому цим Договором або чинним законодавством, але у будь-якому випадку до моменту остаточного його виконання Сторонами. Цей Договір вважається погодженим Замовником та укладеним за місцезнаходженням Виконавця з дати акцептування.</p>
        <p>12.2. Цей Договір укладено згідно із матеріальним правом України.</p>

        <h1>13. ІНШІ УМОВИ ДОГОВОРУ</h1>

        <p>13.1. Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, та рівно всіма правами і повноваженнями, необхідними і достатніми для укладання і виконання цього Договору відповідно до його умов.</p> 
        <p>13.2. Одностороння зміна умов укладеного Договору Замовником чи відмова виконувати умови укладеного Договору Замовником є неприпустимою, за винятків випадків, передбачених цим Договором. Жодна зі Сторін цього Договору не має права передавати свої права та обов’язки третім особам без згоди на це другої Сторони.</p>
        <p>13.3. Інформація, що надається Замовником є конфіденційною. Інформація про Замовника використовується виключно в цілях виконання його замовлення.</p>
        <p>13.4. Виконавець не несе відповідальність за зміст і правдивість інформації, що надається Замовником при оформленні Замовлення. Замовник несе відповідальність за достовірність вказаної при оформленні Замовлення інформації.</p> 
        <p>13.5 Стороні дійшли згоди, що Замовник забезпечує доступ до мережі Інтернет самостійно.</p>
        <p>13.6. Сторони зобов’язуються зберігати конфіденційну інформацію, отриману в результаті виконання цього Договору.</p>
        <p>13.7. Виконавець має право самостійно змінити та/або доповнити умови цього публічного Договору та додатків до нього, включаючи правила надання та отримання Послуг за даним Договором шляхом публікації на Веб-сайті https://deutschmittyson.online нової редакції із зазначенням дати внесення змін.При цьому Виконавець гарантує та підтверджує, що розміщена на Веб-сайті Виконавця поточна редакція цього Договору та додатків до нього, включаючи правила надання та отримання Послуг за даним Договором, є дійсними.</p>






        <h1>14. КОНТАКТИ ВИКОНАВЦЯ:</h1>
        <p>ФОП Севастьянова Анастасія Сергіївна,
        РНОКПП 3367602522,
        Контакти: e-mail: sevastianovaas@gmail.com,
        тел. +4915237277511</p>


        <p>Остання версія Договору датована: 05 липня 2024 року</p>
    </div>
  )
};

export default ContractForm;
