import React from "react"
import { Link } from "react-router-dom";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  return (
    <div>
        <div className="footer">
          <div className="footer-important-links">
            <Link to={'/privacy-policy'}>Політика конфіденційності</Link><Link to={'/contract-form'}>Оферта</Link><Link to={'/refund-policy'}>Політика повернення коштів</Link>
          </div>
          <div className="footer-copyright">
            <div>            
              <p>Copyright 2024 </p>
              <FontAwesomeIcon icon={faCopyright} color="black"/>
            </div>
            <div>
              <a href="https://t.me/wetowl">Ozatskyi Oleksii, ADC, Inc.</a><a href="https://t.me/wetowl"> 
              <FontAwesomeIcon icon={faTelegram} color="blue"/></a>
            </div>
          </div>
        </div>
    </div>
  )
};

export default Footer;
