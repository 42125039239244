import React from 'react';

export const Blue = ({ children }) => (
  <span style={{ color: 'var(--main-color--)' }}>{children}</span>
);

export const LightBlue = ({ children }) => (
  <span style={{ color: 'var(--secondary-color-blue--)' }}>{children}</span>
);

export const Beige = ({ children }) => (
  <span style={{ color: 'var(--secondary-color-beige--)' }}>{children}</span>
);

export const EmphasiseMontserrat = ({ children }) => (
  <span style={{ fontFamily: 'Montserrat', fontWeight: 900 }}>{children}</span>
);

export const EmphasiseEvolventa = ({ children }) => (
  <span style={{ fontFamily: 'Evolventa', fontWeight: 900 }}>{children}</span>
);

export const PinkGradient = ({ children }) => (
  <span style={{ color: '#d78cf2' }}>{children}</span>
);
