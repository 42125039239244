import React, { useState, useEffect } from 'react';
import "../styles/Course1.css"
import { Link } from 'react-router-dom';
import WeInSocialNetwork from "../elements/WeInSocialNetwork"
import { faComments, faRedoAlt, faClipboardCheck, faTachometerAlt, faClock, faSpellCheck, faCommentDots, faSyncAlt, faHeadset, faGlobe, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmphasiseEvolventa, Blue, EmphasiseMontserrat } from "./CustomElements";


const CoursePage = () => {
  const targetDate = new Date("2024-11-10T00:00:00");
  const targetDate2 = new Date("2024-11-20T00:00:00");
  const targetDate3 = new Date("2024-11-22T00:00:00");

  const calculateTimeLeft = (targetDate) => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
  const [timeLeft2, setTimeLeft2] = useState(calculateTimeLeft(targetDate2));
  const [timeLeft3, setTimeLeft3] = useState(calculateTimeLeft(targetDate3));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft2(calculateTimeLeft(targetDate2));
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft3(calculateTimeLeft(targetDate3));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const isTimeUp = timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;
  const isTimeUp2 = timeLeft2.days === 0 && timeLeft2.hours === 0 && timeLeft2.minutes === 0 && timeLeft2.seconds === 0;
  const isTimeUp3 = timeLeft3.days === 0 && timeLeft3.hours === 0 && timeLeft3.minutes === 0 && timeLeft3.seconds === 0;
  const iconsColor = "#004aad";

  return (
    <div>
      <div className="main-container-course-1">
        <div className="header-container-course-1 blue">
          <h1><EmphasiseEvolventa><Blue>Авторський курс «Німецька A1 прям з 0»</Blue></EmphasiseEvolventa></h1>
          <div className='header-container-paragraph'>
            <p><EmphasiseMontserrat>Старт:</EmphasiseMontserrat> 22.11.24</p>
            <p><EmphasiseMontserrat>Формат:</EmphasiseMontserrat> Онлайн, гнучкий графік</p>
            <p><EmphasiseMontserrat>Тривалість:</EmphasiseMontserrat> 3 місяці</p>
            {!isTimeUp2 && <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> 2950 грн
            </p>} 
            {isTimeUp2 && 
            <p>
              <EmphasiseMontserrat>Ціна:</EmphasiseMontserrat> 3250 грн до 22.11
            </p>}
            {!isTimeUp2 && <p>
              З 20.11 3250 грн
            </p>}
          </div>
          <Link><button>Запис закрито</button></Link>
        </div>

        <div className="course-1-container-courselist">
          <h2><EmphasiseEvolventa><Blue>Що ви отримаєте:</Blue></EmphasiseEvolventa></h2>
          <div className='course-1-courselist'>
            <p> <EmphasiseMontserrat>150+ сторінок</EmphasiseMontserrat> домашніх завдань</p>
            <p><EmphasiseMontserrat>12 живих занять</EmphasiseMontserrat> у Zoom з викладачем</p>
            <p><EmphasiseMontserrat>Розмовні завдання</EmphasiseMontserrat> після кожного уроку</p>
            <p><EmphasiseMontserrat>30+ аудіювань</EmphasiseMontserrat> для відпрацювання лексики</p>
            <p><EmphasiseMontserrat>30+ відео-уроків</EmphasiseMontserrat> з граматики «Просто про складне»</p>
            <h2><Blue><EmphasiseEvolventa>Бонус:</EmphasiseEvolventa></Blue></h2>
            <p><EmphasiseMontserrat>Розмовний клуб</EmphasiseMontserrat> у Zoom для тих, хто виконує домашні завдання</p>
            <p><EmphasiseMontserrat>2 живі лекції з психологом</EmphasiseMontserrat> для подолання мовного бар'єра + питання-відповіді</p>
          </div>
        </div>
      </div>

      <WeInSocialNetwork telegram={true} />

      <div className="main-container-course-1 small-padding">
        <div className="course-1-container blue">
          <h1><EmphasiseEvolventa><Blue>Для кого цей курс:</Blue></EmphasiseEvolventa></h1>
          <div className="course-1-for-whom">
            <div>
              <FontAwesomeIcon icon={faSeedling} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто починає з нуля</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faSyncAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто вже вивчав німецьку на рівні A2 або B1, але ще є помилки в базі</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faComments} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто шукає практику та впевненість у спілкуванні</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faClipboardCheck} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче систематизувати знання і врешті почати говорити німецькою</h2>
            </div>
            <div>
              <FontAwesomeIcon icon={faTachometerAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
              <h2>Для тих, хто хоче навчатися у власному темпі</h2>
            </div>
          </div>
          <Link><button>Запис закрито</button></Link>
        </div>

        <div className="course-1-container">
          <h2><EmphasiseEvolventa><Blue>Програма курсу:</Blue></EmphasiseEvolventa></h2>
          <div className="course-1-program">
            <h2>Граматика та структура речень:</h2>
            <p>Особові займенники, зворотні займенники, присвійні займенники</p>
            <p>Präsens, Perfekt, Präteritum</p>
            <p>Порядкові та кількісні числівники</p>
            <p>Побудова речень</p>
            <p>Питальні слова</p>
            <p>Модальні дієслова</p>
            <p>Imperativ</p>
            <p>Konjunktiv. Ввічливі форми</p>
            <p>Kein і nicht</p>
            <p>Прийменники з Dativ, Akkusativ + Прийменники часу і місця</p>
            <p>Відокремлювані/невідокремлювані приставки</p>
            <h2>Вимова та аудіювання:</h2>
            <p>Аудіювання та вправи на сприйняття</p>
            <p>Вимова + вправи</p>
            <h2>Розмовна практика:</h2>
            <p>Розмовні завдання після кожного уроку</p>
            <p>Відпрацювання лексики для реальних життєвих ситуацій</p>
          </div>
        </div>

        <div className="course-1-container">
          <h2 className='text-center-align'><EmphasiseEvolventa><Blue>Переваги нашого авторського курсу з вивчення німецької A1:</Blue></EmphasiseEvolventa></h2>
          <div className="course-1-benefits">
            <div>
                <FontAwesomeIcon icon={faClock} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Доступ до матеріалів 24/7:</EmphasiseEvolventa></h2> 
                  <p>Усі матеріали будуть доступні з першого дня курсу, і ви  навчаєтесь у зручний для вас час у власному темпі.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faHeadset} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Постійний зворотний зв'язок:</EmphasiseEvolventa></h2>
                  <p>Ви зможете ставити запитання через Google Forms, а відповіді будуть на щотижневих Zoom-сесіях, записи яких будуть доступні.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faCommentDots} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Практичні завдання:</EmphasiseEvolventa></h2>
                  <p>Починаючи з 3-го уроку, вам потрібно буде здавати аудіодомашні завдання через Telegram, які я перевірятиму.
                  Живі онлайн уроки: Щотижневі зустрічі в Zoom, де я викладаю, а також відповідаю на питання та даю додаткові пояснення, а Тайс ходить туди сюди, ну а може лежить, спить, грається.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faSpellCheck} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Граматика простими словами:</EmphasiseEvolventa></h2> 
                  <p>Граматика  написана на основі Duden Gramatik (це офіційне джерело німецької граматики — як Oxford для англійської), 
                  викладена за моєю методологією та в моєму стилі — простими словами про складне.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faGlobe} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Життєві та актуальні теми:</EmphasiseEvolventa></h2>
                  <p>Вивчаємо те, що дійсно знадобиться у реальному житті й у повсякденних справах.</p>
                </div>
            </div>
            <div>
                <FontAwesomeIcon icon={faRedoAlt} size = "3x" color = {iconsColor}></FontAwesomeIcon>
                <div className="container-course-1-benefit">
                  <h2><EmphasiseEvolventa>Безкоштовне повторне проходження:</EmphasiseEvolventa></h2>
                  <p>Ви можете переходити в наступні потоки курсу як повноцінний учасник, повторювати матеріал, ставити запитання, здавати домашні завдання і доєднуватись до Zoom-сесій.</p>
                </div>
            </div>
          </div>
          <Link><button className='blue'>Запис закрито</button></Link>
        </div>
      </div>
    </div>  
  );
};

export default CoursePage;
