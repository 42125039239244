import { faInstagram, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import nastyaWithCat from "../img/nastya-with-cat.png"

const WeInSocialNetwork= (props) => {
  return (
    <div>
      {props.instagram && (
        <div className={`social-network-line-promo ${props.additionalClass}`}>
          <img src={nastyaWithCat} alt="anastasia-small" className="social-network-line-promo-image"/>
          <div>
            <h2>Ми в Instagram</h2>
            <a href="https://www.instagram.com/deutsch_mit_tyson/" target="_blank" rel="noreferrer">
              <FontAwesomeIcon size="2x" color="blue" icon={faInstagram}/>
              <h3>Відкрити</h3>
            </a>
          </div>
        </div>
      )}
      {props.telegram && (
        <div className={`social-network-line-promo ${props.additionalClass}`}>
          <img src={nastyaWithCat} alt="anastasia-small" className="social-network-line-promo-image"/>
          <div>
            <h2>Ми в Telegram</h2>
            <a href="https://t.me/+Yjx9ZOltXPg2ZTUy" target="_blank" rel="noreferrer"> 
              <FontAwesomeIcon size="2x" color="blue" icon={faTelegram}/>
              <h3>Відкрити</h3>
            </a>
          </div>
        </div>)}
    </div>
  )
};

export default WeInSocialNetwork;
